import { useEffect, useRef } from "react";
import posters from "../data/posters";
import frames from "../data/frames";
import sizes from "../data/sizes";

export default function ARQuickLookButton({ imageURL, frameId, width, height, onTab, children }) {

    const url = `/api/poster.usdz?image=${imageURL}&frameId=${frameId}&width=${width}&height=${height}#callToAction=${encodeURI("Add to cart")}&checkoutTitle=${encodeURI(`Poster`)}&price=42,49%20€&allowsContentScaling=0`;

    const button = useRef();

    useEffect(() => {
        const listener = (event) => {
            if (event.data === "_apple_ar_quicklook_button_tapped") {
                if (onTab) {
                    onTab();
                }
            }
        }
        const element = button.current;
        element.addEventListener("message", listener, false);
        return () => {
            element.removeEventListener("message", listener)
        }
    })

    return (
        <a ref={button} className="ar-button" href={url} download="poster.usdz" rel="ar">
            <img alt="dummy" style={{
                display: "none"
            }} />
            { children }
        </a>
    )

}