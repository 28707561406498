import "./PosterDetail.css";
import { useHistory, useParams } from "react-router";
import posters from "../data/posters"
import sizes from "../data/sizes"
import frames from "../data/frames"
import ARQuickLookButton from "./ARQuickLookButton";
import { useRef, useState } from "react";
import useStore from "../state";
import ARGlyph from "../assets/icons/ar_glyph.svg";
import { WebXRExperience } from "./webxr/WebXRExperience"
import { useARQuickLookSupported } from "../hooks/useARQuickLookSupported";
import { useWebXRSupported } from "../hooks/useWebXRSupported";

export default function PosterDetail() {
    const history = useHistory();

    let { posterId } = useParams();
    const poster = posters[posterId];

    const [frame, setFrame] = useState("202-Black")
    const [size, setSize] = useState("41x61")

    const quickLookAvailable = useARQuickLookSupported()
    const webxrAvailable = useWebXRSupported()

    const addToCart = useStore(state => state.addToCart)

    const webXRExperienceRef = useRef();

    const addToCartClicked = (selectedPosterId = posterId, selectedFrame = frame, selectedSize = size) => {
        addToCart(selectedPosterId, selectedFrame, selectedSize);
        history.push("/checkout");
    }

    return (
        <div className="poster-detail">
            { poster &&
                <>
                    <h1>{poster.name}</h1>
                    <img className="poster" src={poster.image} alt={poster.name} />

                    <label htmlFor="frame-select">Frame: </label>
                    <select value={frame} onChange={(evt) => setFrame(evt.target.value)} className="minimal" id="frame-select">
                        {Object.keys(frames).map((value) => {
                            return <option key={value} value={value}>{frames[value]}</option>
                        })}
                    </select>

                    <label htmlFor="size-select">Size: </label>
                    <select value={size} onChange={(evt) => setSize(evt.target.value)} className="minimal" id="size-select">
                        {Object.keys(sizes).map((key) => {
                            return <option key={key} value={key}>{sizes[key].name}</option>
                        })}
                    </select>

                    <div className="action-buttons">
                        { quickLookAvailable &&
                            <ARQuickLookButton onTab={() => addToCartClicked()} imageURL={window.location.origin + poster.image} frameId={frame} width={sizes[size].width} height={sizes[size].height}>View in AR <img alt="ar-icon" src={ARGlyph} /></ARQuickLookButton>
                        }
                        { webxrAvailable && 
                            <button className="ar-button" onClick={() => webXRExperienceRef.current.startAR()}>View in AR <img alt="ar-icon" src={ARGlyph} /></button>
                        }
                        <a href="#add" className="add-to-cart" onClick={() => addToCartClicked()}>Add to cart</a>
                    </div>
                    <WebXRExperience ref={webXRExperienceRef} onAddToCart={(posterId, frame, size) => addToCartClicked(posterId, frame, size)} posterId={posterId} frame={frame} size={size} />
                </>
            }
            { !poster &&
                <span>No poster found!</span>
            }
        </div>
    )
}