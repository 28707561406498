import { Link } from "react-router-dom";
import "./PosterListEntry.css";

export default function PosterListEntry({ id, image, name }) {

    return (
        <Link to={`/poster/${id}`}>
            <div className="poster-entry">
                <img src={image} alt={name} />
                <span>{ name }</span>
            </div>
        </Link>
        
    )
}