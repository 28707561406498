import "./CheckoutPage.css"
import posters from "../data/posters";
import sizes from "../data/sizes";
import frames from "../data/frames";
import useStore from "../state";
import ARQuickLookButton from "../components/ARQuickLookButton";
import CancelIcon from "../assets/icons/cancel.svg";
import { Link } from "react-router-dom";

export default function CheckoutPage() {
    const cartItems = useStore(state => state.cartItems)
    const removeFromCart = useStore(state => state.removeFromCart)

    return (
        <div className="checkout-page">
            <h1>Shopping Cart</h1>
            { cartItems.map(item => {
                const poster = posters[item.poster]
                const frame = frames[item.frame]
                const size = sizes[item.size]
                return (
                    <div key={item.uuid} className="cart-item">
                        <img className="poster" src={poster.image} alt={poster.name} />
                        <div>
                            <h3>{poster.name}</h3>
                            <span>{ frame }, { size.name }</span>
                            <ARQuickLookButton imageURL={window.location.origin + poster.image} frameId={item.frame} width={size.width} height={size.height}>View in AR</ARQuickLookButton>
                            <button className="remove-button" onClick={() => removeFromCart(item.uuid)}><img alt="cancel-icon" src={CancelIcon} /></button>
                        </div>
                    </div>
                )
            })}

            { cartItems.length === 0 && 
                <span>The shopping cart is empty!</span>
            }

            <Link className="browse-link" to={"/"}>Browse all posters</Link>
        </div>
    )
}