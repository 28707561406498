import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./App.css";
import CheckoutPage from "./pages/CheckoutPage";
import PosterDetailPage from "./pages/PosterDetailPage";
import PosterListPage from "./pages/PosterListPage";

export default function App() {
  return (
    <Router>
      <div>
        <header>
          <h1><Link to={"/"}>JUNIQE</Link></h1>
          <Link className="cart-link" to={"/checkout"}></Link>
        </header>
        <div className="ar-demo-container">
          <span>This is an AR demo of the JUNIQE store.</span>
        </div>
        <main>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/checkout">
              <CheckoutPage />
            </Route>
            <Route path="/poster">
              <PosterDetailPage />
            </Route>
            <Route exact path="/">
              <PosterListPage />
            </Route>
          </Switch>
        </main>
        <p className="notice">A demo by <a href="https://marlon-lueckert.de">Marlon Lückert</a> and <a href="https://matthiashamann.work">Matthias Hamann</a>!</p>
      </div>
    </Router>
  );
}