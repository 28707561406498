import BrooklynBridge from "../assets/images/brooklyn-bridge.jpg";
import CactusHeart from "../assets/images/cactus-heart.jpg";
import Dolomites from "../assets/images/dolomites.jpg";
import Frida from "../assets/images/frida.jpg";
import SunFLock from "../assets/images/sun-flock.jpg";

const posters = {
    "brooklyn-bridge": {
        image: BrooklynBridge,
        name: "Brooklyn Bridge"
    },
    "cactus-heart": {
        image: CactusHeart,
        name: "Cactus Heart"
    },
    "dolomites": {
        image: Dolomites,
        name: "Dolomites"
    },
    "frida": {
        image: Frida,
        name: "Frida"
    },
    "sun-flock": {
        image: SunFLock,
        name: "Sun Flock"
    }
}

export default posters;