const sizes = {
    "14x19": {
        width: 14,
        height: 19,
        name: "14x19 cm"
    },
    "21x31":  {
        width: 21,
        height: 31,
        name: "21x31 cm"
    },
    "31x46":  {
        width: 31,
        height: 46,
        name: "31x46 cm"
    },
    "41x61":  {
        width: 41,
        height: 61,
        name: "41x61 cm"
    },
    "61x91":  {
        width: 61,
        height: 91,
        name: "61x91 cm"
    },
}

export default sizes;