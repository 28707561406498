import { Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import PosterDetail from "../components/PosterDetail";

export default function PosterDetailPage() {
    const match = useRouteMatch();
    return (
        <div className="poster-detail">
            <Switch>
                <Route path={`${match.path}/:posterId`}>
                    <PosterDetail />
                </Route>
                <Route path={match.path}>
                    <h3>No poster selected.</h3>
                    <Link to={"/"}>Back to the poster list</Link>
                </Route>
            </Switch>
        </div>
    )

}