export function useWebXRSupported() {
    if ('xr' in navigator) {
        return navigator.xr
            .isSessionSupported('immersive-ar')
            .then(function (supported) {
                return supported
            })
            .catch(() => {
                return false
            })
    } else {
        return false
    }
}