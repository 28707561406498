import "./WebXRExperience.css";
import { useLoader } from '@react-three/fiber'
import { ARCanvas, useHitTest, useXR } from "@react-three/xr";
import { useRef, Suspense, forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import sizes from "../../data/sizes";
import { Matrix4 } from "three";
import EndIcon from "../../assets/icons/end.svg";
import { a, useSpring, config } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import frames from "../../data/frames"
import posters from "../../data/posters"

export const WebXRExperience = forwardRef(({ posterId, frame, size, onAddToCart }, ref) => {

    useEffect(() => {
        setFrame(frame)
    }, [frame])

    useEffect(() => {
        setSize(size)
    }, [size])

    const [selectedFrame, setFrame] = useState(frame)
    const [selectedSize, setSize] = useState(size)

    const sessionInit = { requiredFeatures: ['hit-test'], optionalFeatures: ['dom-overlay'] };

    useImperativeHandle(
        ref,
        () => ({
            startAR() {
                sessionInit.domOverlay = {
                    root: domOverlayRef.current
                }
                navigator.xr.requestSession('immersive-ar', sessionInit).then(onSessionStarted)
            }
        }),
    )

    const onSessionStarted = async (session) => {
        renderer.xr.setReferenceSpaceType('local')
        await renderer.xr.setSession(session)
    }

    const domOverlayRef = useRef()

    const [renderer, setRenderer] = useState();
    const [text, setText] = useState("Move your device around and find a wall");
    const [placingActivated, setPlacingActivated] = useState(false);
    const [isPlaced, setIsPlaced] = useState(false)
    const [isPresenting, setIsPresenting] = useState(false)

    const height = 220;
    const [{ y }, api] = useSpring(() => ({ y: height }))

    const addToCart = () => {
        renderer.xr.getSession().end();
        onAddToCart(posterId, selectedFrame, selectedSize);
    }

    const open = ({ canceled }) => {
        // when cancel is true, it means that the user passed the upwards threshold
        // so we change the spring config to create a nice wobbly effect
        api.start({ y: 0, immediate: false, config: canceled ? config.wobbly : config.stiff })
    }
    const close = (velocity = 0) => {
        api.start({ y: height, immediate: false, config: { ...config.stiff, velocity } })
    }

    const bind = useDrag(
        ({ last, velocity: [, vy], delta: [, my], cancel, canceled }) => {
            // when the user releases the sheet, we check whether it passed
            // the threshold for it to close, or if we reset it to its open positino
            if (last) {
                y.get() + my > height * 0.5 || vy > 0.5 ? close(vy) : open({ canceled })
            } else {
                api.start({ y: y.get() + my, immediate: true })
            }
        },
        { rubberband: true }
    )
    return (
        <>
            <div ref={domOverlayRef} id="dom-overlay" className={`${isPresenting ? "isPresenting" : ""} ${isPlaced ? "isPlaced" : ""}`} >
                <img className="end-icon" alt="end-icon" src={EndIcon} onClick={() => renderer.xr.getSession().end()} />
                <div className="place-container" onClick={() => {
                    if (placingActivated) {
                        setIsPlaced(!isPlaced)
                    }
                }}>
                    <span>{text}</span>
                </div>
                <a.div className={`options-container ${isPlaced ? "isPlaced" : ""}`} {...bind()} style={{ bottom: `calc(-100vh + ${height}px)`, y }}>
                    <div className="handle"></div>
                    <div className="cart-section">
                        <div>
                            <h1>{`${posters[posterId].name} (${selectedSize})`}</h1>
                            <h2>{`${frames[selectedFrame]}`}, 42,99 €</h2>
                        </div>
                        <button onClick={addToCart}>Add to cart</button>
                    </div>
                    <div className="separator"></div>

                    <label htmlFor="frame-select">Frame: </label>
                    <select value={selectedFrame} onChange={(evt) => setFrame(evt.target.value)} className="minimal" id="frame-select">
                        {Object.keys(frames).map((value) => {
                            return <option key={value} value={value}>{frames[value]}</option>
                        })}
                    </select>

                    <label htmlFor="size-select">Size: </label>
                    <select value={selectedSize} onChange={(evt) => setSize(evt.target.value)} className="minimal" id="size-select">
                        {Object.keys(sizes).map((key) => {
                            return <option key={key} value={key}>{sizes[key].name}</option>
                        })}
                    </select>

                    <button className="reset-position" onClick={() => setIsPlaced(false)}>Reset position</button>
                </a.div>
            </div>
            <ARCanvas className="webxr-container" onCreated={({ gl }) => setRenderer(gl)} sessionInit={sessionInit}>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={null}>
                    <ParentToHitTest activated={!isPlaced} onFirstHit={() => {
                        setText("Tab to place the poster")
                        setPlacingActivated(true)
                    }}>
                        <PosterModel posterId={posterId} frame={selectedFrame} size={selectedSize} />
                    </ParentToHitTest>
                </Suspense>
                <XRState onPresentingChanged={(isPresenting) => setIsPresenting(isPresenting)} />
            </ARCanvas>
        </>
    )

})

function PosterModel({ posterId, frame, size }) {

    const [scene, setScene] = useState(null);

    useEffect(() => {
        setScene(null)
        const modelURL = `/api/model.gltf?image=${posterId}&frame=${frame}&size=${sizes[size]}`
        const loader = new GLTFLoader();
        // Load a glTF resource
        loader.load(modelURL, function (gltf) {
                setScene(gltf.scene);
            }
        );
    }, [size, frame, posterId])

    if (scene) {
        return <primitive object={scene} />
    } else {
        return null
    }
}

function XRState({ onPresentingChanged }) {

    const { isPresenting } = useXR()

    useEffect(() => {
        if (onPresentingChanged) {
            onPresentingChanged(isPresenting)
        }
    }, [isPresenting, onPresentingChanged])

    return null;
}

const matrix4 = new Matrix4();
function ParentToHitTest({ activated = true, onFirstHit, children }) {
    const ref = useRef()

    const [alreadyHit, setAlreadyHit] = useState(false)

    useHitTest((hitMatrix, hit) => {
        if (activated) {
            hitMatrix.decompose(ref.current.position, ref.current.rotation, ref.current.scale)
            const yaw = Math.atan2(hitMatrix.elements[4], hitMatrix.elements[6])
            ref.current.rotation.set(0, yaw, 0)
            if (!alreadyHit) {
                if (hitMatrix !== matrix4) {
                    if (onFirstHit) {
                        onFirstHit()
                    }
                    setAlreadyHit(true)
                }
            }
        }
    })

    return (
        <group ref={ref}>
            {children}
        </group>
    )
}