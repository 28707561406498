import create from 'zustand'

const useStore = create(set => ({
  cartItems: [],
  addToCart: (poster, frame, size) => set(state => ({ cartItems: [...state.cartItems, { uuid: uuidv4(), poster, frame, size }] })),
  removeFromCart: (uuid) => set(state => {
      const filteredCartItems = state.cartItems.filter((item) => item.uuid !== uuid)
      return {
        cartItems: filteredCartItems
      }
  })
}))

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }
  

export default useStore;