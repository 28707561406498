import "./PosterListPage.css";
import PosterListEntry from "../components/PosterListEntry";
import posters from "../data/posters"

export default function PosterListPage() {

    return (
        <div className="poster-list">
            { Object.keys(posters).map((id) => {
                return <PosterListEntry key={id} id={id} image={posters[id].image} name={posters[id].name} />
            }) }
        </div>
    )

}