const frames = {
    "202-Black": "Standard Frame - Black",
    "202-White": "Standard Frame - White",
    "202-Gold": "Standard Frame - Gold",
    "202-Silver": "Standard Frame - Silver",
    "202-Copper": "Standard Frame - Copper",
    "204-Black": "Wooden Frame - Black",
    "204-White": "Wooden Frame - White",
    "204-Oak": "Wooden Frame - Oak",
    "201-Black": "Premium Wooden Frame - Black",
    "201-White": "Premium Wooden Frame - White",
    "201-Oak": "Premium Wooden Frame - Oak",
}

export default frames;